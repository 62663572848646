/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloClient, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, config } from '../../config';
import { EventTypes, GameMode, GraphQLErrorsType, ISettledBet, IUserBalance, lineSets } from '../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setFreeRoundBonus,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsBuyFeatureSpin,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringCollectAnimation,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenedMessageBanner,
  setIsPopupFreeRoundsOpened,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setReplayBet,
  setReplayFreeSpinBets,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setWinAmount,
} from '../../gql/cache';
import { IConfig, ISlotConfig } from '../../gql/d';
import { placeBetGql } from '../../gql/mutation';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  replayBetGql,
} from '../../gql/query';
import SlotMachine from '../../slotMachine';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { canPressSpin, formatNumber, isFreeSpinsMode, saveReelPosition, showCurrency } from '../../utils';

import { IPlaceBetInput } from './d';

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};
const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { id: slotId, lineSet } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  const stressful = useReactiveVar(setStressful);

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinsMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const betCompleteCallback = (placeBet: ISettledBet, client: ApolloClient<unknown>): void => {
    window.dispatchEvent(new CustomEvent('placeBetCompleted'));
    const clonnedPlaceBet: ISettledBet = JSON.parse(JSON.stringify(placeBet));
    if (clonnedPlaceBet.rewards) {
      const replayBonusIndex = clonnedPlaceBet?.rewards.findIndex(
        (reward) => reward.__typename === 'ReplayBonusReward',
      );
      if (replayBonusIndex > -1) {
        clonnedPlaceBet.rewards[replayBonusIndex].__typename = 'BetBonusReward';
      }
    }
    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: clonnedPlaceBet.balance.placed,
        },
      },
    });
    SlotMachine.getInstance().setResult(clonnedPlaceBet);
    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    const callBack = () => {
      const win = placeBet.bet.result.winCoinAmount;
      const lastThreeSpins = [...setGameHistory().slice(1), !!win];
      const thirdWinInRow = _.reduce(lastThreeSpins, (acc, item) => acc && item);
      setGameHistory(lastThreeSpins);

      if (placeBet.bet.coinAmount * placeBet.bet.lineSet.coinAmountMultiplier * 5 <= win && !thirdWinInRow) {
        BgmControl.fadeInMelo(3000);
      }
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.settled,
          },
        },
      });
      saveReelPosition(placeBet.bet.result.reelPositions);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError(error) {
      window.dispatchEvent(new CustomEvent('placeBetCompleted'));
      if (error.graphQLErrors.some((err) => err.extensions?.type === GraphQLErrorsType.INSUFFICIENT_FUNDS)) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    },

    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const [getReplayBet] = useLazyQuery<{ placeBet: ISettledBet }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      const placeBetClone = (() => {
        if (typeof window.structuredClone !== 'function') {
          return JSON.parse(JSON.stringify(placeBet)) as ISettledBet;
        }
        return window.structuredClone(placeBet);
      })();

      betCompleteCallback(placeBetClone, client);
    },
  });

  const setPlayBgm = () => {
    setIsShowSoundToast(false);
    BgmControl.playBgm();
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinsMode(setGameMode())) return;
        eventManager.emit(EventTypes.UPDATE_WIN_VALUE, formatNumber(setCurrency(), 0, showCurrency(setCurrency())));
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });

        if (setReplayBet()) {
          const replayFreeSpins = setReplayFreeSpinBets();
          const betRound = setCurrentBonus().currentRound - 1;
          if (replayFreeSpins.length && betRound <= replayFreeSpins.length) {
            setReplayBet(replayFreeSpins[setCurrentBonus().currentRound - 1]);
          } else {
            setReplayBet(replayFreeSpins[0]);
            setReplayFreeSpinBets([]);
          }
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          const input: IPlaceBetInput = {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSets[GameMode.REGULAR],
          };
          const freeRoundBonus = setFreeRoundBonus() && setFreeRoundBonus().isActive;
          if (freeRoundBonus) {
            // TODO(FRB) Turn it OFF if you spin during a test
            input.userBonusId = setFreeRoundBonus().id;
          }
          fnGet({
            variables: {
              input,
            },
          });
        }

        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioHowl.stop({ type: ISongs.SFX_UI_Close });
        AudioHowl.play({ type: ISongs.XT001S_SpinStart });
        AudioHowl.play({ type: ISongs.XT001S_SpinStart_Loop, stopPrev: true });
        if (AudioHowl.isRestricted) {
          handleChangeRestriction();
        }
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }

      if (AudioHowl.isRestricted) {
        AudioHowl.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => setPlayBgm(),
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );

  useEffect(() => {
    const buyFeature = (id: string) => {
      if (setReplayBet()) return;
      setIsBuyFeatureSpin(true);
      eventManager.emit(EventTypes.UPDATE_WIN_VALUE, formatNumber(setCurrency(), 0, showCurrency(setCurrency())));
      setWinAmount(0);
      setLastRegularWinAmount(0);
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });

      if (setReplayBet()) {
        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: lineSets[GameMode.REGULAR],
              userBonusId: id,
            },
          },
        });
      }

      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });

      if (AudioHowl.isRestricted) {
        AudioHowl.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => setPlayBgm(),
        );
      }
    };

    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeature);
    return () => {
      eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeature);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);
      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

      const popupFreeRoundsOpened = setIsPopupFreeRoundsOpened();

      if (
        autoSpinsLeft ||
        bonus ||
        stopOnWin ||
        stopOnWinExceeds ||
        balanceIncrease ||
        balanceDecrease ||
        popupFreeRoundsOpened
      ) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (setGameMode() === GameMode.REGULAR && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (setIsDuringCollectAnimation()) {
      eventManager.emit(EventTypes.SKIP_COLLECT_ANIMATION);
      return;
    }

    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (stressful.show) {
          return;
        }

        if (!data?.isEnabledSpaceSpin) {
          return;
        }

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            bonusCurrentRound: setCurrentBonus()?.currentRound || 0,
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isInTransition: setIsInTransition(),
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isDuringCollectAnimation: setIsDuringCollectAnimation(),
          }) ||
          setIsPopupFreeRoundsOpened()
        ) {
          return;
        }

        if (setIsOpenedMessageBanner()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          return;
        }

        if (setIsDuringCollectAnimation()) {
          eventManager.emit(EventTypes.SKIP_COLLECT_ANIMATION);
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes.slice(2), (acc, item) => acc && item);
    const stop = _.reduce(winThreeTimes, (acc, item) => acc || item);
    if (play) {
      BgmControl.fadeInMelo(500);
    }

    if (!stop) {
      BgmControl.fadeOutMelo(3000);
    }
  }, [winThreeTimes]);

  useEffect(() => {
    let id: number;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped) {
      id = window.setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
