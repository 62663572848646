import { Container, Sprite, Texture } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { IPlaceBetInput } from '../../components/SpinButton/d';
import { ISongs, mappedAudioSprites } from '../../config';
import {
  BonusData,
  BonusRoundData,
  EventTypes,
  GameMode,
  ISettledBet,
  MessageFreeSpinsBannerProps,
  MessageWinBannerProps,
  lineSets,
} from '../../global.d';
import {
  setBetAmount,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setIsDuringCollectAnimation,
  setNextResult,
  setReplayBet,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { placeBetGql } from '../../gql/mutation';
import { replayBetGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import Backdrop from '../backdrop/backdrop';
import { BgmControl } from '../bgmControl/bgmControl';
import {
  BASE_WIN_AMOUNT_LIMIT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import GameReplay from '../gameView/gameReplay';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';

import { BingoEffects } from './bingoEffects';
import { FreeSpinsSymbolContainer } from './freeSpinsSymbolContainer';
import { BottomTextPanel } from './panels/bottomTextPanel';
import { MultiplierPanels } from './panels/multiplierPanels';
import { RatePanel } from './panels/ratePanel';
import { Track } from './track';

export type FreeSpinsState = 'idle' | 'reveal' | 'spin' | 'stop' | 'collect' | 'end';

export class FreeSpinsView extends Container {
  private box: Sprite;

  private bingo: BingoEffects;

  private mpPanel: MultiplierPanels;

  private symbolContainer: FreeSpinsSymbolContainer;

  private bottom: BottomTextPanel;

  private reel: number[];

  private state: FreeSpinsState;

  private roundData: BonusRoundData[] = [];

  private roundIndex: number;

  private animaton?: Animation;

  constructor() {
    super();

    this.box = new Sprite(Texture.from(ResourceTypes.bonusFrame));
    this.box.anchor.set(0.5);

    this.bingo = new BingoEffects();
    this.mpPanel = new MultiplierPanels();
    this.symbolContainer = new FreeSpinsSymbolContainer();
    this.reel = [...Array(15)].map((v) => 0);
    this.bottom = new BottomTextPanel();

    const gameReplay = new GameReplay();
    gameReplay.position.set(-700, -370);

    this.state = 'idle';
    this.roundIndex = 0;

    this.addChild(this.box, this.bingo, this.symbolContainer, this.mpPanel, new RatePanel(), this.bottom, new Track());
    this.addChild(gameReplay);

    this.visible = false;

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, ({ mode }) => {
      this.visible = isFreeSpinsMode(mode);
    });

    eventManager.addListener(EventTypes.INITIALIZE_FREESPINS, this.init.bind(this));
    eventManager.addListener(EventTypes.SET_FREESPINS_STATE, this.setState.bind(this));
    eventManager.addListener(EventTypes.SKIP_COLLECT_ANIMATION, () => {
      setIsDuringCollectAnimation(false);
      this.animaton?.skip();
      this.animaton = undefined;
    });
  }

  private init(data: BonusData) {
    this.roundIndex = 0;
    this.state = 'idle';
    this.reel = [...Array(15)].map((v) => 0);
    this.roundData = data.legend;
    this.bingo.clearAllBingoLine();
    const mp = [...data.multipliers.columns, ...data.multipliers.rows];
    this.mpPanel.init(mp);
    this.updateReel(data.legend[0].landingDiamonds);
    this.symbolContainer.initReel(this.reel, mp);
    this.bottom.setRemain(data.legend[0].remainingRounds);

    if (setBrokenGame() && setCurrentFreeSpinsTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      });
    }
  }

  private setState(nextState: FreeSpinsState) {
    const prevState = this.state;
    this.state = nextState;
    this.onChangeState(prevState, nextState);
  }

  private async onChangeState(prevState: FreeSpinsState, nextState: FreeSpinsState) {
    if (nextState === 'idle') {
      return;
    }
    if (nextState === 'reveal') {
      this.mpPanel.reveal();
      return;
    }
    if (nextState === 'spin') {
      const remain = this.roundData[this.roundIndex].remainingRounds - 1;
      this.roundIndex += 1;
      this.updateReel(this.roundData[this.roundIndex].landingDiamonds);
      const delay = Tween.createDelayAnimation(1000);
      delay.addOnStart(() => {
        this.symbolContainer.startSpin();
        this.bottom.setRemain(remain);
      });
      delay.addOnComplete(() => {
        this.symbolContainer.stopSpin(this.reel, remain === 0);
      });
      delay.start();
      return;
    }
    if (nextState === 'stop') {
      if (this.roundData[this.roundIndex].remainingRounds > 0 && this.roundData.length - 1 !== this.roundIndex) {
        this.bottom.setRemain(this.roundData[this.roundIndex].remainingRounds);
        eventManager.emit(EventTypes.SET_FREESPINS_STATE, 'spin');
      } else {
        const placeBet = await this.placeBet();
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + placeBet.bet.result.winCoinAmount);
        setNextResult(placeBet);
        eventManager.emit(EventTypes.SET_FREESPINS_STATE, 'collect');
      }
      return;
    }
    if (nextState === 'collect') {
      BgmControl.stopBgm();
      AudioHowl.play({ type: ISongs.XT001S_BGM_DiaCount, loop: true });
      this.animaton = this.symbolContainer.startCollectAnimation();
      setIsDuringCollectAnimation(true);
    }
    if (nextState === 'end') {
      setIsDuringCollectAnimation(false);
      eventManager.emit(EventTypes.END_FREESPINS);
    }
  }

  private async placeBet() {
    if (setReplayBet()) {
      const replayResult = await client.query<{ placeBet: ISettledBet }>({
        query: replayBetGql,
        variables: { betId: setReplayBet() },
      });
      return replayResult.data.placeBet;
    }

    return (
      await client.mutate<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>({
        mutation: placeBetGql,
        variables: {
          input: {
            slotId: setSlotConfig().id,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSets[GameMode.FREE_SPINS],
            userBonusId: setCurrentBonus().id,
          },
        },
      })
    ).data!.placeBet;
  }

  private updateReel(landings: Array<{ position: number; score: number }>) {
    landings.forEach((land) => (this.reel[land.position] = land.score));
  }

  private createFreeSpinsMessage(props: MessageFreeSpinsBannerProps): void {
    this.addChild(new MessageFreeSpinsBanner(props).init());
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    const song =
      props.totalWinAmount / setBetAmount() < BASE_WIN_AMOUNT_LIMIT
        ? ISongs.XT001S_TotalWinBanner
        : ISongs.XT001S_TotalWinBanner_Big;

    const totalWinDelay = Tween.createDelayAnimation(mappedAudioSprites[song].duration);
    totalWinDelay.addOnStart(() => {
      BgmControl.stopBgm();
      AudioHowl.stop({ type: ISongs.XT001S_BGM_DiaCount });
    });
    totalWinDelay.addOnSkip(() => {
      AudioHowl.fadeOut(500, song);
    });
    AudioHowl.play({ type: song, stopPrev: true });
    eventManager.emit(EventTypes.SET_FREESPINS_RESULT, 0, 0, setCurrentBonus().data.rewardAmount);
    eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

    totalWinDelay.start();
    this.addChild(
      new MessageWinBanner({
        ...props,
        callback: () => {
          totalWinDelay.skip();
          if (props.callback) props.callback();
        },
      }).init(),
    );
  }

  private resize(
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    const coff = pivotX === SAFE_AREA_LANDSCAPE_PIVOT_X ? 0.9 : 0.785;

    this.scale.set(scale * coff);
    this.pivot.set((pivotX - SLOTS_CONTAINER_WIDTH / 2) / coff, (pivotY - SLOTS_CONTAINER_HEIGHT / 2) / coff);
  }
}
